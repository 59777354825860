import { useContext } from 'react';
import Favorite from '@material-ui/icons/Favorite';
import UserContext from "../../src/UserContext";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { Box, IconButton } from '@material-ui/core';
import TitledText from '../../components/text/TitledText';
import styles from '../../styles/QuestionCard.module.css';
import { useTranslation } from "react-i18next";


function extractContent(html) {
    return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
}

function trimString(str, length) {
    if (str) {
        let dots = "";
        if (str.length > length) dots = "...";
        return str.slice(0, length - 1) + dots;
    }
}

export default function QuestionCard({ favoriteSnackbar, setFavoriteSnackbar, ...props }) {

    const [t,] = useTranslation();
    let { keycloakstate, } = useContext(UserContext);
    let isLoggedIn = keycloakstate?.authenticated === true;

    const handleClick = (e) => {
        e.stopPropagation(); //dont activate redirect
        e.preventDefault(); //dont activate href of underlying anchor
        if (isLoggedIn) {
            const makeRequests = async () => {
                let exist = await props.addToFavorites(props.id, props.favoriteFlag);
                if (exist) {
                    setFavoriteSnackbar({
                        ...favoriteSnackbar,
                        open: true,
                        severity: "warning",
                        message: t('alreadyInFavorites')
                    });
                } else {
                    setFavoriteSnackbar({
                        ...favoriteSnackbar,
                        open: true,
                        severity: "success",
                        message: t('addedToFavorites')
                    });
                }
            };
            makeRequests();
        } else {
            setFavoriteSnackbar({
                ...favoriteSnackbar,
                open: true,
                severity: "warning",
                message: t('favoritesPage.logIn')
            });
        }
    };

    function isFavorite() {
        return props.favoriteFlag;
    }

    return (
        <>
            <Box
                height="100%"
                borderRadius={16}
                className={styles.container}
            >
                <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="stretch"
                    alignItems="flex-start"
                >
                    <Box width="100%">
                        <Box px={3} pt={3}>
                            <TitledText title={t('question') + ": "}>
                                {props.question}
                            </TitledText>
                        </Box>
                    </Box>
                    <Box p={2} width="100%" flexGrow={1}>
                        <Box width="100%" height="100%" borderRadius={16} className={styles.answer}>
                            <Box height="100%" px={3} pb={3} pt={2} display="flex" flexDirection="column" justifyContent="space-between">
                                <TitledText title={t('answer') + ": "}>
                                    {extractContent(props.answer).length <= 250 ?
                                        extractContent(props.answer)
                                        :
                                        trimString(extractContent(props.answer), 250)
                                    }
                                </TitledText>

                                <Box width="36px" alignSelf="flex-end">
                                    <IconButton onClick={handleClick}>
                                        {(isFavorite()) ? <Favorite /> : <FavoriteBorder />}
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}